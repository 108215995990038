import { useEffect, useState } from "react";
import { useMetamask, ConnectMetamask, loadSmartContract } from "../metamask";
import dapp from "../metamask/dapp";
import logo from '../assets/logo.svg';

export default function HelloMetamask() {
  const { user, contract, setContract } = useMetamask();
  const [balance, setBalance] = useState(null); // Add this line
  const [error, setError] = useState<string | null>(null);
  const setSmartContract = () => {
    setContract(loadSmartContract(dapp.withdrawalAddress, dapp.withdrawalAbi));
  };
  const networkNames = {
    '0x1': 'Ethereum Mainnet',
    '0x12c': 'zksync',
  };

  useEffect(() => {
    setSmartContract();
    connectToNetwork('0x144');
  }, []);

  useEffect(() => {
    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    });

    // Clean up the event listener
    return () => {
      window.ethereum.removeListener('chainChanged', () => {
        window.location.reload();
      });
    };
  }, []);

  useEffect(() => {
    if (contract) {
      const fetchBalance = async () => {
        await getBalance();
      };

      fetchBalance();
    }
  }, [contract]);

  const getBalance = async () => {
    if (contract) {
      const balance = await contract.getMyBalance();
      setBalance(balance.toString());
    }
  };

  const withdraw = async () => {
    try {
      if (contract) {
        await contract.withdrawal();
      }
    } catch (e) {
      const errorMessage = e.toString();
      const reason = errorMessage.split('reason')[1].split('"')[1];
      setError(reason);
    }
  };

  const connectToNetwork = async (chainId: string) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{
          chainId,
          // Add other network parameters like chainName, nativeCurrency, rpcUrls, blockExplorerUrls
        }],
      });
    } catch (error) {
      let e: any = error;
      setError(e.message.toString());
    }
  };

  return (
    <div className="flex flex-col items-center bg-slate-100 h-screen justify-center">
      <div className="shadow-lg text-center border border-slate-300 bg-white p-10 rounded-md">
        <div className="flex justify-center mb-4">
          <img src={logo} alt="Logo" style={{ width: '100px', height: '100px' }} />
        </div>
        {!user.isConnected ? (
          <ConnectMetamask />
        ) : (
          <>
            {/* <div className="my-3 uppercase text-sm tracking-widest font-light">
              Connected chain ID
            </div>
            <div className="my-3 tracking-widest font-extrabold">
              {window.ethereum.networkVersion}
            </div> */}
            <div className="my-3 uppercase text-sm tracking-widest font-light">
              Connected wallet
            </div>
            <div className="my-3 tracking-widest font-extrabold">
              {user.address}
            </div>
            <div className="my-3 uppercase text-sm tracking-widest font-light">
              My satis balance
            </div>
            <div className="my-3 tracking-widest font-extrabold">
              {((balance ? balance : 0) / 1e6).toFixed(6)} USDC
            </div>
            <div
              className="my-2 bg-orange-500 text-white px-5 py-3 rounded-md my-2 font-bold tracking-wider text-center hover:cursor-pointer uppercase mt-6"
              onClick={withdraw}
            >
              Withdraw
            </div>
            <div className="flex justify-center">
              {error && <div className="text-red-500" style={{ wordWrap: 'break-word', maxWidth: '300px', textAlign: 'center' }}>{error}</div>}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
